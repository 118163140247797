
import { Options, Vue, prop } from 'vue-class-component'
import { YButton, YInputText, YForm } from '@pacprotocol/yanui'
import { Browser } from '@capacitor/browser'
import { SplashScreen } from '@capacitor/splash-screen';

@Options({
    components: {
        YInputText,
        YButton,
        YForm,
    },
    metaInfo() {
        return {
            title: 'Login',
        }
    },
})
export default class FilesView extends Vue {
    private email: string = ''
    private password: string = ''
    private two_factor_code: string | undefined = undefined
    private error: any = null

    async mounted() {
        if (this.$route.query.confirmed === '1') {
            this.$yanui.toast.show(
                'Your E-Mail has been confirmed!\n\nPlease log-in!',
                {
                    type: 'is-success',
                },
            )
            this.$plausible.trackEvent('User E-Mail Verified', {
                props: { success: 'yes' },
            })
        }
        await SplashScreen.hide()
    }

    private login() {
        this.$auth0.loginWithRedirect({
            authorizationParams: {
                redirect_uri: this.$utils.is_native()
                ? process.env.VUE_APP_AUTH0_CALLBACK_URL_NATIVE
                : process.env.VUE_APP_AUTH0_CALLBACK_URL
            },
            openUrl: async (url: string) => {
                if (this.$utils.is_native()) {
                    await Browser.open({
                        url,
                        windowName: '_self',
                        presentationStyle: 'popover',
                    })
                } else {
                    window.location.replace(url)
                }
            },
        })
    }
    private register() {
        this.$auth0.loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup',
                redirect_uri: this.$utils.is_native()
                ? process.env.VUE_APP_AUTH0_CALLBACK_URL_NATIVE
                : process.env.VUE_APP_AUTH0_CALLBACK_URL
            },
            openUrl: async (url: string) => {
                console.log('REGISTER', url)
                if (this.$utils.is_native()) {
                    await Browser.open({
                        url,
                        windowName: '_self',
                        presentationStyle: 'popover',
                    })
                } else {
                    window.location.replace(url)
                }
            },
        })
    }
}
