
import { Options, Vue, prop } from 'vue-class-component'

import { YButton, YInputText, YForm } from '@pacprotocol/yanui'

@Options({
    components: {
        YInputText,
        YButton,
        YForm,
    },
})
export default class FilesView extends Vue {
    private password: string = ''
    private seed_phrase: string = ''
    private error: any = null
    private confirmcode: string = ''

    created() {
        this.confirmcode = this.$route.params.confirmcode as string
    }

    async mounted() {
        let valid = false
        try {
            valid = await this.$yanid.forgot_password_valid(this.confirmcode)
        } catch (e) {
            //
        }
        if (!valid) {
            this.$router.replace({ name: 'Login' })
            this.$yanui.toast.show('Password Request Expired or Invalid!', {
                type: 'is-danger',
            })
        }
    }

    private confirm() {
        //@ts-ignore
        if (this.$refs.passref.validate()) {
            this.$yanid
                .reset_password(
                    this.confirmcode,
                    this.password,
                    this.seed_phrase,
                )
                .then(() => {
                    this.$router.replace({ name: 'Login' })
                    this.$yanui.toast
                        .show('Password Changed!\n\nPlease Login again!', {
                            type: 'is-success',
                        })(this.$i ? this.$i : this)
                        .$plausible.trackEvent('User Password Changed', {
                            props: { success: 'yes' },
                        })
                })
                .catch((e: any) => {
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'User Password Changed',
                        { props: { success: 'no' } },
                    )
                    if (e.statusCode === 400) {
                        this.error = e
                    }
                    this.$yanui.toast.show(e.message, { type: 'is-danger' })
                })
        }
    }
}
