
import { Options, Vue, prop } from 'vue-class-component'
import LegalText from '@/components/Legal/LegalText.vue'
import { YButton, YInputText, YForm } from '@pacprotocol/yanui'
import { SplashScreen } from '@capacitor/splash-screen';

@Options({
    components: {
        YInputText,
        YButton,
        YForm,
        LegalText,
    },
})
export default class FilesView extends Vue {
    private email: string = ''
    private password: string = ''
    private error: any = null
    private registered: boolean = false
    private legal_agreed: boolean = false
    private legal_alert: boolean = false

    async mounted() {
        if (this.$route.query.expired === '1') {
            this.$yanui.toast
                .show(
                    "Your E-Mail has been expired or account doesn't exists.\n\nPlease register it again!",
                    { type: 'is-danger', duration: 15000 },
                )(this.$i ? this.$i : this)
                .$plausible.trackEvent('User E-Mail Verified', {
                    props: { success: 'no' },
                })
        }
        await SplashScreen.hide()
    }

    private register() {
        //@ts-ignore
        if (this.$refs.passref.validate() && this.email) {
            if (!this.legal_agreed) {
                this.$yanui.toast.show(
                    'In order to register you need to accept the legal terms',
                    {
                        type: 'is-danger',
                        duration: 5000,
                    },
                )
                this.legal_alert = true
                return
            }

            this.$yanid
                .register(this.email, this.password)
                .then(() => {
                    this.registered = true
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'User Registered',
                        { props: { success: 'yes' } },
                    )
                })
                .catch((e) => {
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'User Registered',
                        { props: { success: 'no' } },
                    )
                    if (e.statusCode === 400) {
                        this.error = e
                    } else {
                        console.error(e)
                    }
                    if (typeof e.message == 'string') {
                        this.$yanui.toast.show(e.message, { type: 'is-danger' })
                    }
                })
        }
    }
}
