
import { Options, Vue, prop } from 'vue-class-component'

import { YButton, YInputText, YForm } from '@pacprotocol/yanui'

@Options({
    components: {
        YInputText,
        YButton,
        YForm,
    },
})
export default class FilesView extends Vue {
    private email: string = ''
    private error: any = null
    private continued: boolean = false

    mounted() {
        if (this.$route.query.confirmed === '1') {
            this.$yanui.toast.show(
                'Your E-Mail has been confirmed!\n\nPlease log-in!',
                { type: 'is-success' },
            )
        }
    }

    private send() {
        //@ts-ignore
        if (this.email) {
            this.$yanid
                .forgot_password(this.email.trim())
                .then(() => {
                    this.continued = true
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'User Password Forgot',
                        { props: { success: 'yes' } },
                    )
                })
                .catch((e: any) => {
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'User Password Forgot',
                        { props: { success: 'no' } },
                    )
                    if (e.statusCode === 400) {
                        this.error = e
                    }
                    this.$yanui.toast.show(e.message, { type: 'is-danger' })
                })
        }
    }

    private send_again() {
        this.send()
        this.continued = true
    }
}
